#categories-mobile {
  .category-container {
    display: grid;
    grid-template-columns: auto 1fr auto;
    gap: 1rem;
    align-items: center;
    border-radius: var(--size-borderRadius);
    font-size: 0.9rem;
    font-weight: 600;
    padding: 8px;
    width: max-content;
    border: 1px solid var(--color-gray2);

    .shape svg {
    }

    .pes {
      background-color: var(--color-gray2);
    }
    .pes {
      display: flex;
      border-radius: 50px;
      justify-content: center;
      align-items: center;
      padding: 6px 10px;
      font-size: 0.8rem;
    }
    &.new {
      background-color: transparent;
      margin-top: 10px 10px 0px 10px;
    }
  }

  .no-clickable {
    .pes {
      opacity: 0.5;
    }
  }

  .selected {
    background-color: var(--color-blue-light);

    .title {
      color: var(--color-main);
    }

    .pes {
      background-color: var(--color-info);
      .number {
        color: white;
      }
      svg {
        fill: white;
        color: white;
      }
    }

    .shape {
      background-color: var(--color-info);
      svg {
        fill: white;
        color: white;
      }
    }

    .pes {
      opacity: 1;
    }
    &:hover {
      background-color: var(--color-infoBackground);
    }
  }
  &.big {
    .category-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 1rem;
      background-color: var(--color-gray1);
      border-radius: var(--size-borderRadius);
      min-width: 120px;
      min-height: 120px;
      font-size: 1rem;
      font-weight: 600;
      padding: 1rem;
    }
    .selected {
      background: var(--color-infoBackground);
      color: var(--color-main);
      // svg {
      //   path:not([stroke="none"]) {
      //     fill: var(--color-main);
      //     stroke: var(--color-main);
      //     color: var(--color-main);
      //   }
      // }
      &:hover {
        background-color: var(--color-infoBackground-hover);
        //   color: white;
        //   svg {
        //     path:not([stroke="none"]) {
        //       fill: white;
        //       color: white;
        //       stroke: white;
        //     }
        //   }
      }
    }
  }
}

#categories-desktop {
  padding: 0px 0px 4px 8px;

  h3 {
    padding: 0 0 0 1rem;
    margin: 0;
    margin-bottom: 15px;
  }
  p {
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    padding: 0 1rem;
    font-size: 0.8rem;
    font-weight: 600;
  }
  .categories-container {
    margin: 0;
    padding: 0;

    .category-item {
      margin: 0px;
      &:hover {
        background-color: var(--color-gray3);
      }
    }
    .category-container {
      display: grid;
      grid-template-columns: auto 1fr auto;
      gap: 0.25rem;
      border: none;
      margin: 0;
      padding: 0;
      width: 100%;
      max-width: unset;
      text-align: left;
      border-left: 3px solid var(--color-gray2);
      border-radius: 0;
      position: relative;
      justify-content: space-between;
      align-items: center;
      font-size: 0.8rem;
      font-weight: 600;
      padding: 4px 8px 5px 10px;

      .pes {
        display: flex;
        background-color: var(--color-text-light);
        color: var(--color-gray0);
        border-radius: 50px;
        justify-content: center;
        align-items: center;
        margin-left: 1.5rem;
        padding: 5px;
        font-size: 0.75rem;
        min-width: 23px;
        .number {
          color: var(--color-gray0);
        }
      }
      &.active {
        display: grid;
      }
      &.inactive {
        display: none;
      }
      &.clickable:hover {
        background-color: var(--color-gray2);
        &.selected {
          background-color: var(--color-blue-light);
        }
      }
      &.no-clickable {
        opacity: 0.65;
        .pes {
          background-color: var(--color-gray1);
          color: var(--color-gray15);

          .number {
            color: var(--color-text-light);
          }
        }
        &.selected {
          .number {
            color: white;
          }
        }
      }
      &.selected {
        padding-left: 1rem;
        border-color: var(--color-active);

        background-color: var(--color-blue-light);
        color: var(--color-active);
        svg {
          fill: var(--color-active);
          color: var(--color-active);
          stroke: var(--color-active);
        }
        .pes {
          background-color: var(--color-active);
          div {
            color: white;
          }
        }
      }
    }
  }
}
