@use "./variablesSCSS.scss";

header {
  width: 95%;
  margin-inline: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  z-index: 11;
  top: 20px;
  * {
    user-select: none;
  }

  #logo {
    max-width: 85px;
    > * {
      position: unset !important;
    }
  }

  #title {
    font-size: 1.25rem;
    font-weight: 600;
    text-transform: uppercase;
    color: var(--color-text);
    margin-bottom: 0;
    text-align: center;
  }

  nav {
    display: grid;
    grid-template-columns: 185px 1fr;
    align-items: center;

    #breadcrumb {
      margin-bottom: 0;
    }
  }
}

#support-btn,
#connexion-btn {
  margin: 0;
  background: var(--color-gray0);
  width: 55px;
  height: 55px;
  aspect-ratio: 1/1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 300px;
  padding: 0;
  cursor: pointer;
  &:hover {
    background: var(--color-gray2);
  }
}

#header-user {
  z-index: 2;
  position: relative;
  margin: 0;
  display: flex;
  flex-direction: column;

  #user {
    background-color: var(--color-gray0);
    padding: 5px 5px 5px 5px;
    cursor: pointer;
    font-weight: 600;
    font-size: 0.9rem;
    &:hover {
      background: var(--color-gray2);
    }
  }

  #header-user_dropdown {
    background-color: var(--color-gray0);

    position: absolute;
    top: 100%;
    width: 100%;
    right: 0px;
    border-radius: 0px 0px 25px 25px;

    .item {
      padding: 10px 15px;
      display: grid;
      align-items: center;
      grid-template-columns: 15px 1fr;
      gap: 10px;
      cursor: pointer;
      &:last-child {
        padding-bottom: 15px;
        border-radius: 0px 0px 25px 25px;
      }
      &:hover {
        background: var(--color-gray2);
      }

      // &:not(:last-child) {
      border-top: 2px solid var(--color-gray1);
    }
  }
  &.closed {
    #user {
      border-radius: 25px;
    }
  }
  &.open {
    #user {
      border-radius: 25px 25px 0px 0px;
    }
  }
}

body > footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  // margin-top: 4rem;
  // bottom: 0;
  // position: relative;
  #copyright {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--color-gray0);
    padding: 10px 0 13px;
    font-weight: 500;
    font-size: 0.9rem;
  }
}

@media (max-width: variablesSCSS.$media-tablet) {
  #support-btn {
    display: none;
  }

  footer {
    #copyright {
      flex-direction: column;
    }
  }
}

@media (max-width: variablesSCSS.$media-phone) {
  header {
    #title {
      padding: 15px;
    }
  }
}
