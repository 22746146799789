.button--close {
  background-color: rgba(0, 0, 0, 0);
  border: none;
  margin: 0 !important;
  padding: 0.5rem;
  transition: all 0.2s ease;
  svg {
    margin: 0;
  }
  &.clickable:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
}
