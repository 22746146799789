:root {
  --color-white: #ffffff;
  --color-background: #e0eef1;
  ///
  --color-background1: #ddebff;

  --color-title: #252d6a;
  --color-text: #191f55;
  --color-link: rgb(32, 58, 198);
  --color-text-light: rgb(41, 47, 90);
  --color-text-soft: rgb(139, 143, 192);

  /// MAIN COLORS
  --color-main: #1465df;
  --color-active: #2178fb;
  --color-blue-light: hsl(210, 100%, 94%);
  --color-blue-light-hover: #dde5fc;
  --color-account: #ccdbff;
  --color-blue-background: #dbe1ff;
  --color-infoBackgroundGlass: rgba(220, 234, 255, 0.51);

  --color-secondary: rgb(201, 50, 154);
  --color-orange: #f79b23;
  --color-orangeBackground: #f1e2ce;
  --color-yellow: #f1c943;
  --color-yellowBackground: #fff8e6;

  --color-category: rgba(201, 50, 154, 0.12);
  --color-secondary-background: #f6edf7;
  --color-secondary-background2: #f1e0ed;
  --color-secondary-text: #671f4d;

  ////// GRAY
  --color-shadow: rgba(0, 0, 0, 0.3);
  --color-glass: rgba(255, 255, 255, 0.45);
  --color-gray0-glass: rgba(250, 250, 255, 0.6);
  --color-gray0-transparent: rgb(245, 248, 252);
  --color-darken0: #0000000a;
  --color-darken1: #00000018;
  --color-darken2: #00000041;

  /////
  --color-gray0: rgb(248, 248, 252);
  --color-gray1: rgb(240 240 245);
  --color-gray2: rgb(220, 220, 230);
  --color-gray3: rgb(208, 212, 220);
  --color-gray4: rgb(202, 202, 210);
  --color-gray5: rgb(184, 184, 200);
  --color-gray6: rgb(178, 180, 190);
  --color-gray7: rgb(168, 170, 180);
  --color-gray8: rgb(158, 160, 170);
  --color-gray9: rgb(148, 150, 160);
  --color-gray10: rgb(148, 150, 150);
  --color-gray11: rgb(130, 135, 140);
  --color-gray12: rgb(120, 130, 130);
  --color-gray13: rgb(110, 120, 120);
  --color-gray14: rgb(100, 110, 110);
  --color-gray15: rgb(90, 100, 100);
  --color-gray16: rgb(90, 90, 90);
  --color-gray17: rgb(80, 80, 80);
  --color-gray18: rgb(70, 70, 70);
  --color-gray19: rgb(60, 60, 60);
  --color-gray20: rgb(50, 50, 50);
  --color-gray21: rgb(40, 40, 40);
  --color-gray22: rgb(30, 30, 30);
  --color-gray23: rgb(20, 20, 20);
  --color-gray24: rgb(10, 10, 10);
  --color-gray25: rgb(0, 0, 0);

  // TOASTS
  --color-warningBackground: #fff8e6;
  --color-warningBackground-hover: rgb(255, 241, 202);
  --color-successBackground: #e7f1ee;
  --color-successBackground-hover: #b7ffeb;
  --color-infoBackground: #c9d9ff;
  --color-infoBackground-hover: #bbccff;
  --color-errorBackground: rgb(255, 238, 238);
  --color-errorBackground-hover: rgb(250, 219, 219);
  --color-warning: #ffaa19;
  --color-warning-hover: rgb(236, 159, 25);
  --color-success: #00cdac;
  --color-success-hover: rgb(8, 194, 163);
  --color-info: #4150d8;
  --color-info-hover: rgb(65, 77, 194);
  --color-error: #e84a5f;
  --color-error-hover: rgb(194, 60, 78);
  --color-violet: #6d24dd;

  /// FONTS
  --font-title: "Montserrat", sans-serif;
  --font-main: "Open Sans", sans-serif;
  /// SIZES
  --size-borderRadius: 22px;
  --size-input: 550px;
  /// MEDIA
  /// $media-phone: 500px;
  --media-tablet: 768px;
  --media-small-screen: 920px;
  --media-medium-screen: 1100px;
  --media-large-screen: 1600px;
}

[data-theme="dark"] {
  --color-white: #ffffff;
  --color-background: rgb(41, 42, 56);
  ///
  --color-background1: rgb(39 41 45);

  --color-title: rgb(223, 230, 240);
  --color-text: rgb(229, 235, 248);
  --color-link: rgb(133 170 255);
  --color-active: rgb(77 129 245);
  --color-text-light: rgb(206, 218, 233);
  --color-text-soft: #7c99d1;

  /// MAIN COLORS
  --color-main: rgb(70 118 229);
  --color-secondary: #9b5397;
  --color-orange: #f1a540;
  --color-orangeBackground: #5f3600;
  --color-yellow: rgb(255, 185, 68);
  --color-yellowBackground: #5e4506;
  --color-secondary-text: rgb(115, 71, 83);
  --color-account: #26375f;

  ////// GRAY
  --color-glass: rgb(60, 60, 60, 0.2);
  --color-gray0-glass: rgba(0, 0, 0, 0.6);
  --color-gray0-transparent: #2f3541;
  --color-gray0: rgb(47 53 65);
  --color-gray1: rgb(34, 37, 46);
  --color-gray2: rgb(38, 43, 53);
  --color-gray3: #22282f;
  --color-gray4: rgb(73, 81, 88);
  --color-gray5: rgb(111, 121, 131);
  --color-gray6: rgb(112, 122, 133);
  --color-gray7: rgb(158, 171, 184);
  --color-gray8: rgb(168, 179, 190);
  --color-gray9: rgb(185, 190, 199);
  --color-gray10: rgb(203, 210, 230);
  --color-gray11: rgb(216, 216, 216);
  --color-gray12: rgb(199, 199, 199);
  --color-gray13: rgb(231, 231, 231);
  --color-gray14: rgb(235, 235, 235);
  --color-gray15: rgb(233, 233, 233);
  --color-gray16: rgb(241, 241, 238);
  --color-gray17: rgb(238, 238, 238);
  --color-gray18: rgb(240, 240, 240);
  --color-gray19: rgb(238, 238, 238);
  --color-gray20: rgb(240, 240, 240);
  --color-gray21: rgb(240, 240, 240);
  --color-gray22: rgb(255, 255, 255);
  --color-gray23: rgb(248, 248, 248);
  --color-gray24: rgb(245, 245, 245);
  --color-gray25: #ffffff;

  // TOASTS
  --color-warningBackground: rgb(73 65 50);
  --color-warningBackground-hover: rgb(99, 86, 64);
  --color-successBackground: rgb(50 73 62);
  --color-successBackground-hover: rgb(63, 97, 81);
  --color-infoBackground: rgb(43 54 97);
  --color-infoBackground-hover: rgb(51, 67, 128);
  --color-errorBackground: rgb(67 36 36);
  --color-errorBackground-hover: rgb(128, 57, 57);
  --color-warning: rgb(255, 185, 68);
  --color-success: rgb(0, 205, 172);
  --color-info: rgb(96 121 253);
  --color-error: rgb(255 108 108);

  //

  --color-secondary-background: #2b1d30;
  --color-secondary-background2: #462f40;
  --color-secondary-text: #e9c8dd;

  // --color-category: rgb(58, 41, 65);

  --color-blue-light: rgb(40 48 67);
  --color-blue-light-hover: rgb(53, 62, 91);
  --color-blue-background: rgb(23 62 153 / 68%);
  --color-infoBackgroundGlass: rgba(30, 34, 35, 0.51);
}
