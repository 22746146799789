.tab-wrapper {
  display: grid;
  width: 100%;
  background-color: none;
  user-select: none;
  background-color: var(--color-gray0-transparent);
  overflow: hidden;
  border-radius: 300px;
  padding: 5px 5px 0 5px;
  hr {
    display: none;
  }

  .tabs-container {
    display: grid;
    text-align: center;
    width: 100%;

    .tab-item {
      margin: auto;
      position: relative;
      display: flex;
      justify-content: center;
      padding: 10px 5px 16px 5px;
      align-items: center;
      font-weight: 600;
      font-size: 0.9rem;
      color: var(--color-gray12);
      cursor: pointer;

      .icon-container {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--color-infoBackground);
        width: auto;
        height: 35px;
        aspect-ratio: 1/1;

        border-radius: 80px;
        svg {
          width: 55%;
          height: auto;
          aspect-ratio: 1/1;
          color: var(--color-main);
          stroke: var(--color-main);
        }
        .bubble {
          position: absolute;
          top: -5px;
          right: -5px;
          width: 11px;
          height: 11px;
          border-radius: 50px;
          background-color: var(--color-error);
        }
      }
      .count {
        background-color: var(--color-gray1);
        border-radius: 50px;
        border: 1px solid var(--color-gray4);
        padding: 5px 9px;
        margin-left: 15px;
        font-size: 0.85rem;
        font-weight: 600;
      }
      &:hover {
        color: var(--color-text);
      }
      &.selected {
        cursor: auto;
        color: var(--color-active);
        .count {
          border-color: var(--color-active);
          background-color: var(--color-active);
          color: white;
        }
        .icon-container {
          background-color: var(--color-active);
          svg {
            color: white;
            stroke: white;
          }
        }
      }

      .underline {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 4px;
        border-radius: 30px;
        width: calc(100% - 10px);

        margin-left: auto;
        margin-right: auto;
        background: var(--color-active);
      }
    }
  }
}

.tab-wrapper.mobile {
  overflow: hidden;
  .tabs-container {
    min-width: 0;
    .tab-item {
      padding-left: 10vw;
      padding-right: 10vw;
      svg {
        vertical-align: middle;
        padding: 0;
      }
    }
  }

  .swiper {
    mask-image: linear-gradient(to right, transparent 0%, black 5% 95%, transparent 100%);
    max-width: 100%;
    .swiper-button-next,
    .swiper-button-prev {
      color: var(--color-title);
      position: absolute;
      font-size: small;
      &::after {
        font-size: 1.5rem;
      }
    }
    .swiper-button-next {
      right: 10px;
    }
    .swiper-button-prev {
      left: 10px;
    }
    .swiper-slide {
      width: fit-content !important;
    }
    svg {
      height: 20px;
      width: 20px;
    }
    .swiper-button-disabled {
      color: transparent;
    }
  }
}
