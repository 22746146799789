.text-input {
  position: relative;
  &.empty {
    background: none;
    border: 1px solid var(--color-gray4);
  }

  .validation {
    top: 50%;
    position: absolute;
    right: 10px;
    transform: translateY(-50%);
    fill: var(--color-success);
  }
}

.copy-text-input {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  gap: 5px;
  border: 1px solid var(--color-gray2);
  background-color: var(--color-gray1);
  border-radius: 16px;
  padding: 4px 4px 4px 10px;
  &:hover {
    background: var(--color-gray3);
    input,
    textarea {
      background: var(--color-gray3);
    }
  }

  input,
  textArea {
    border: none;
    cursor: pointer;
    padding: 6px;
    font-size: 1rem;

    &:focus-visible {
      outline: none;
    }
  }

  .icon-button {
    margin-top: 3px;
    margin-right: 3px;
  }

  .info {
    background-color: var(--color-infoBackground);
    color: var(--color-info);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 12px;

    display: flex;
    align-items: center;
    justify-content: center;
  }
}
