@use "./../../../styles/commons/variablesSCSS.scss";
@use "./../../../styles/commons/commons.scss";

.message-box {
  padding: 10px 18px;
  border-radius: 20px;
  max-width: 530px;
  display: flex;
  gap: 12px;
  position: relative;
  margin-bottom: 10px;
  user-select: none;

  &.vertical {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 20px;

    .icon {
      width: 100%;
      svg {
        width: 25%;
        max-width: 115px;
        height: auto;
        margin-top: 10px;
      }
    }
    h2 {
      margin-top: 5px;
      font-size: 1.4rem;
    }

    h2,
    p,
    span,
    h3,
    h4,
    em,
    strong {
      text-align: center;
      margin-left: auto;
      margin-right: auto;
    }

    .message {
      font-weight: 700;
    }
    &.small {
      .message {
        font-weight: 700;
        font-size: 1.2rem;
      }
    }
    &.medium {
      .message {
        font-size: 1.3rem;
      }
    }
  }

  .message {
    line-height: normal;
    opacity: 1;
    width: 100%;
    padding-right: 10px;
    margin-block: 5px;
    h2 {
      font-size: 2rem;
    }
    h3 {
      font-weight: 700;
      font-size: 1.4rem;
    }
    h4 {
      font-size: 1.1rem;
    }
    p {
      font-size: 1rem;
    }
  }

  .icon {
    margin-top: 2px;
    svg {
      max-width: 120px;
      aspect-ratio: 1/1;
    }
  }

  .close-btn {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // INFO ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  @mixin colorContent($name, $backgroundColor, $color, $opacity) {
    &.#{$name} {
      background-color: $backgroundColor;
      color: $color;
      opacity: $opacity;

      .icon {
        @include commons.svgColor($color);
      }

      .message :not(button, button *),
      .message {
        color: $color;
      }
      button.transparent {
        border-color: $color;
        color: $color;

        @include commons.svgColor($color);

        &:hover:not([disabled]) {
          border-color: $color;
          background-color: $color;
          color: white;
          @include commons.svgColor(white);
        }
      }
    }
  }

  @include colorContent("info", var(--color-infoBackground), var(--color-info), 1);
  @include colorContent("good", var(--color-successBackground), var(--color-success), 1);
  @include colorContent("fail", var(--color-errorBackground), var(--color-error), 1);
  @include colorContent("warning", var(--color-warningBackground), var(--color-warning), 1);
  @include colorContent("neutral", var(--color-gray2), var(--color-text), 0.8);

  // &.info {
  //   background-color: var(--color-infoBackground);
  //   border-color: var(--color-info);
  //   color: var(--color-info);
  //   &,
  //   > * .message * {
  //     color: var(--color-info) !important;
  //     border-color: var(--color-info);
  //   }
  //   path {
  //     stroke: var(--color-info);
  //   }

  //   button.clickable.transparent {
  //     border-color: var(--color-info);
  //   }

  //   button.clickable.transparent:hover {
  //     background-color: var(--color-info);
  //     border-color: var(--color-info);
  //     &,
  //     * .message * {
  //       color: var(--color-infoBackground) !important;
  //       svg,
  //       path {
  //         color: var(--color-infoBackground);
  //         stroke: var(--color-infoBackground);
  //       }
  //     }
  //   }
  // }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // FAIL ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  // &.fail {
  //   background-color: var(--color-errorBackground);
  //   border-color: var(--color-error);
  //   color: var(--color-error);
  //   &,
  //   > * .message * {
  //     color: var(--color-error) !important;
  //     border-color: var(--color-error);
  //     stroke: var(--color-error);
  //   }
  //   path {
  //     stroke: var(--color-error);
  //   }

  //   button.clickable.transparent {
  //     border-color: var(--color-error);
  //   }

  //   button.clickable.transparent:hover {
  //     background-color: var(--color-error);
  //     border-color: var(--color-error);

  //     &,
  //     * {
  //       color: var(--color-errorBackground) !important;
  //       svg,
  //       path {
  //         color: var(--color-errorBackground);
  //         stroke: var(--color-errorBackground);
  //       }
  //     }
  //   }
  // }
  // &.good {
  //   background-color: var(--color-successBackground);
  //   border-color: var(--color-success);
  //   color: var(--color-success);
  //   > *,
  //   .message > * {
  //     color: var(--color-success) !important;
  //     border-color: var(--color-success);
  //   }
  //   path {
  //     stroke: var(--color-success);
  //   }

  //   button.clickable.transparent {
  //     border-color: var(--color-success);
  //     * {
  //       color: var(--color-success);
  //     }
  //   }
  //   button.clickable.transparent:hover {
  //     background-color: var(--color-success);
  //     border-color: var(--color-success);
  //     &,
  //     * {
  //       color: var(--color-successBackground) !important;
  //       svg,
  //       path {
  //         color: var(--color-successBackground);
  //         stroke: var(--color-successBackground);
  //       }
  //     }
  //   }
  // }

  // &.warning {
  //   background-color: var(--color-warningBackground);
  //   border-color: var(--color-warning);
  //   color: var(--color-warning);
  //   > *,
  //   .message * {
  //     color: var(--color-warning) !important;
  //     border-color: var(--color-warning) !important;
  //   }
  //   svg {
  //     path {
  //       color: var(--color-warning) !important;
  //       stroke: var(--color-warning) !important;
  //     }
  //   }

  //   button {
  //     &:hover {
  //       background: var(--color-warning);
  //       color: var(--color-warningBackground);
  //       border-color: var(--color-warning);
  //     }
  //   }

  //   button.clickable.transparent {
  //     border-color: var(--color-warning) !important;
  //   }

  //   button.clickable.transparent:hover {
  //     background-color: var(--color-warning) !important;
  //     border-color: var(--color-warning) !important;
  //     &,
  //     * {
  //       color: var(--color-white) !important;
  //       svg,
  //       path {
  //         color: var(--color-white) !important;
  //         stroke: var(--color-white) !important;
  //       }
  //     }
  //   }
  // }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // NEUTRAL ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  // &.neutral {
  //   background-color: var(--color-gray2);
  //   border: 1px solid var(--color-gray2);
  //   color: var(--color-text);
  //   opacity: 0.8;

  //   svg {
  //     color: var(--color-text-soft) !important;
  //   }

  //   button.clickable.transparent {
  //     border-color: var(--color-gray3);
  //     &:hover {
  //       background-color: var(--color-gray10);
  //       border-color: var(--color-gray10);
  //       &,
  //       * {
  //         color: var(--color-warningBackground);
  //         svg,
  //         path {
  //           color: var(--color-warningBackground);
  //           stroke: var(--color-warningBackground);
  //         }
  //       }
  //     }
  //   }
  // }

  &.small {
    padding: 7px 15px;
    .message {
      font-size: 0.9rem;
    }
  }
  &.medium {
    padding: 9px 17px;
    .message {
      font-size: 0.95rem;
    }
  }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// OTHERS ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

.tile {
  .message-box.neutral {
    background: var(--color-darken0);
  }
}

@media (max-width: variablesSCSS.$media-phone) {
  .message-box {
    flex-direction: column;

    .message {
      text-align: center;
    }

    .icon {
      margin: auto;
      svg {
        width: 100px;
        height: auto;
      }
    }
  }
}
