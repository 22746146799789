.tile-choice {
  border: 2px solid transparent;
  background: var(--color-gray1);
  gap: 10px;
  padding: 20px;
  border-radius: 15px;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  color: var(--color-gray12);
  > *:not(.shape *, .badge, .badge *, button, button *, .tooltip *) {
    color: inherit;
  }

  .img-container {
    background: var(--color-gray2);
  }

  &.white {
    background: var(--color-gray0);
  }

  &.clickable:hover {
    cursor: pointer;
    background: var(--color-blue-light);

    .img-container {
      background: var(--color-blue-light-hover);
      border-color: var(--color-blue-light-hover);
      .cls-1 {
        fill: var(--color-gray1);
      }
    }
  }

  &.empty {
    background: transparent;
    border-color: var(--color-gray5);
    cursor: pointer;

    color: var(--color-active);

    .img-container {
      background: var(--color-gray1);
    }

    &.clickable:hover {
      background: var(--color-gray2);

      .img-container {
        background: var(--color-gray3);
        .cls-1 {
          fill: var(--color-gray4);
        }
      }
    }
  }

  &.selected {
    background-color: var(--color-active);
    color: white;
    cursor: pointer;

    .shape:not([style*="background"]) {
      background-color: var(--color-main);

      svg * {
        fill: var(--color-white);
        color: var(--color-white);
      }
    }

    .img-container {
      background: var(--color-main);
    }

    &:focus-visible {
      outline-color: var(--color-text-light);
    }
    &.clickable:hover {
      background: var(--color-main) !important;

      .shape:not([style*="background"]) {
        background-color: var(--color-active);
      }

      .img-container {
        background: var(--color-active);
        .cls-1 {
          fill: var(--color-infoBackground);
        }
      }
    }
  }
}
