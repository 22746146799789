button {
  border: none;
  // font-family: var(--font-main);
  margin: 0px;
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  word-wrap: normal;
  gap: 10px;
  color: inherit;
  font-family: inherit;
  user-select: none;
}

button.button,
button.icon-button {
  font-size: 0.9rem;
  font-weight: 600;
  border-radius: 20px;
  border: 1px solid transparent;
  font-family: var(--font-main);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  word-wrap: normal;
  gap: 10px;
  max-width: var(--size-input);
  color: white;

  svg {
    flex-shrink: 0;
  }

  // Only one child
  &:has(> :last-child:nth-child(1)) {
    grid-template-columns: auto;
  }
  // Two children
  &:has(> :last-child:nth-child(2)) {
    grid-template-columns: auto 1fr;
  }

  .legend {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    justify-content: center;
  }

  svg {
    color: var(--color-gray12);
  }

  // TYPE

  &.primary {
    background: var(--color-active);
    .legend {
      color: white;
    }
    svg {
      color: white;
      circle:not(.secondary) {
        stroke: white;
      }
    }

    &.clickable:hover {
      background-color: var(--color-main);
    }

    &.clickable:focus-visible {
      outline-color: var(--color-text-light);
    }
  }

  &.primary-light {
    background: var(--color-blue-light);
    color: var(--color-active);
    .legend {
      color: var(--color-main);
    }
    font-weight: 500;
    svg {
      color: var(--color-main);
      circle:not(.secondary) {
        stroke: var(--color-main);
      }
    }
    &.clickable:hover {
      background: var(--color-blue-light-hover);
    }
  }

  &.white {
    background-color: var(--color-gray0-transparent);
    color: var(--color-gray15);

    &.clickable:hover {
      background-color: var(--color-gray3);
    }
  }

  &.transparent {
    background-color: inherit;
    border: 1px solid var(--color-gray2);

    color: var(--color-gray15);

    font-weight: 600;
    svg {
      color: var(--color-gray15);
      circle:not(.secondary) {
        stroke: var(--color-gray10);
      }
    }
    &.clickable:hover {
      background-color: var(--color-gray2);
      border: 1px solid var(--color-gray3);
      color: var(--color-gray15);
    }
  }

  &.dark {
    background-color: var(--color-text);

    color: var(--color-gray0);

    &.clickable:hover {
      background-color: var(--color-text-light);
    }
    svg {
      color: var(--color-gray0);
      circle:not(.secondary) {
        stroke: var(--color-gray0);
      }
    }
  }

  &.error,
  &.red {
    background: var(--color-error);
    color: var(--color-white);

    font-weight: 500;

    svg {
      color: var(--color-white);
      circle:not(.secondary) {
        stroke: var(--color-errorBackground);
      }
    }
    &.clickable:hover {
      background: var(--color-error-hover);
    }
  }

  &.error-light,
  &.red-light {
    background: var(--color-errorBackground);
    color: var(--color-error);

    font-weight: 500;
    svg {
      color: var(--color-error);
      circle:not(.secondary) {
        stroke: var(--color-error);
      }
    }
    &.clickable:hover {
      background: var(--color-errorBackground-hover);
    }
  }

  &.secondary-light {
    background: var(--color-secondary-background);

    color: var(--color-secondary);

    font-weight: 500;
    svg {
      color: var(--color-secondary);
      circle:not(.secondary) {
        stroke: var(--color-secondary);
      }
    }
    &.clickable:hover {
      background: var(--color-secondary);

      color: var(--color-secondary-background);

      svg {
        color: var(--color-secondary-background);
        path,
        circle:not(.secondary) {
          stroke: var(--color-secondary-background);
        }
      }
    }
  }

  &.gray {
    background: var(--color-gray2);

    color: var(--color-gray17);

    font-weight: 500;
    svg {
      color: var(--color-gray17);
      circle:not(.secondary) {
        stroke: var(--color-gray13);
      }
    }
    &.clickable:hover {
      background: var(--color-gray5);

      color: var(--color-gray19);

      svg {
        color: var(--color-gray19);
        stroke: var(--color-gray19);
      }
    }
  }

  &.gray-light {
    background: var(--color-gray1);

    color: var(--color-gray16);

    font-weight: 500;
    svg {
      color: var(--color-gray16);
      circle:not(.secondary) {
        stroke: var(--color-gray16);
      }
    }
    &.clickable:hover {
      background: var(--color-gray2);
      color: var(--color-gray17);
    }
  }

  &.warning,
  &.yellow {
    background: var(--color-warning);

    color: var(--color-warningBackground);

    font-weight: 500;
    svg {
      color: var(--color-warningBackground);
      circle:not(.secondary) {
        stroke: var(--color-warningBackground);
      }
    }
    &.clickable:hover {
      background: var(--color-warning-hover);
    }
  }

  &.warning-light,
  &.yellow-light {
    background: var(--color-warningBackground);
    color: var(--color-warning);
    font-weight: 500;
    svg {
      color: var(--color-warning);
      circle:not(.secondary) {
        stroke: var(--color-warning);
      }
    }
    &.clickable:hover {
      background: var(--color-warningBackground-hover);
    }
  }

  &.success,
  &.green {
    background: var(--color-success);
    color: var(--color-successBackground);
    font-weight: 500;
    svg {
      color: var(--color-successBackground);
      circle:not(.secondary) {
        stroke: var(--color-successBackground);
      }
    }
    &.clickable:hover {
      background: var(--color-success-hover);
    }
  }

  &.success-light,
  &.green-light {
    background: var(--color-successBackground);
    color: var(--color-success);
    font-weight: 500;
    svg {
      color: var(--color-success);
      circle:not(.secondary) {
        stroke: var(--color-success);
      }
    }
    &.clickable:hover {
      background: var(--color-successBackground-hover);
    }
  }

  &.info,
  &.blue {
    background: var(--color-info);
    color: var(--color-white);
    font-weight: 500;
    svg {
      color: var(--color-white);
      circle:not(.secondary) {
        stroke: var(--color-white);
      }
    }
    &.clickable:hover {
      background: var(--color-info-hover);
    }
  }

  &.info-light,
  &.blue-light {
    background: var(--color-infoBackground);

    color: var(--color-info);

    font-weight: 500;
    svg {
      color: var(--color-info);
      circle:not(.secondary) {
        stroke: var(--color-info);
      }
    }
    &.clickable:hover {
      background: var(--color-infoBackground-hover);
    }
  }

  &.empty {
    margin: 0px;
    padding: 0px;
    background-color: transparent;
    grid-template-columns: auto;
    color: inherit;
    svg {
      color: inherit;
      circle:not(.secondary) {
        stroke: var(--color-text);
      }
    }
    &.clickable:hover {
      background-color: rgba(0, 0, 0, 0.03);
    }
  }

  // SIZE
  &.small {
    font-size: 0.9rem;
    line-height: 0.9rem;
    margin: 1.5rem auto;
    border-radius: 10px;
    padding: 9px 14px 9px 10px;
  }

  &.medium {
    font-size: 0.95rem;
    line-height: 0.9rem;
    font-weight: 600;
    border-radius: 14px;
    padding: 16px 30px;
    margin: 1.5rem auto;
  }
  &.big {
    font-size: 1rem;
    line-height: 1.1rem;
    padding-top: 25px;
    padding-bottom: 25px;
    padding-left: 60px;
    padding-right: 60px;
    margin: 1.5rem auto;
    border-radius: 19px;
  }

  // FORM
  &.circle {
    border-radius: 50px;
  }

  &.disabled,
  &[disabled] {
    opacity: 0.5;

    &,
    *,
    path {
      cursor: not-allowed;
    }
  }
}
